
import { defineComponent } from "vue";
import variables from "@/router/api";
import BrokerInvoicesDropdownOptions from "@/components/individual/BrokerInvoicesDropdownOptions.vue";

export default defineComponent({
  name: "invoices-list",
  components: { BrokerInvoicesDropdownOptions },
  props: {
    invoices: Array
  },
  data() {
    return {
      link: ""
    };
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    }
  }
});
